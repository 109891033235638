import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserDetectorHelper {

  isMobile(): boolean {
    return window.navigator.maxTouchPoints > 0;
  }
}