<div class="row m-5">
  <div class="col-sm-12 col-md-6">
    <div class="monitor-container w-100">
      <div>
        <ng-container *ngIf="cameraStatus === 'LOADING'">
          <div class="alert alert-primary" role="alert">
            <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <p>
                {{
                  translationsHelper.getTranslationByKey("VS_PREPARING_SESSION")
                }}
              </p>
            </div> 
          </div>
        </ng-container>
        <ng-container *ngIf="cameraStatus === 'NO_CAMERAS'">
          <div class="alert alert-danger" role="alert">
            <p>
              {{
                translationsHelper.getTranslationByKey(
                  "VS_DEVICE_HAS_NO_CAMERAS"
                )
              }}
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="cameraStatus === 'NO_PERMISSION'">
          <div class="alert alert-danger text-center" role="alert">
            <p>
              {{
                translationsHelper.getTranslationByKey(
                  "CAMERA_PERMISSION_DENIED"
                )
              }}
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="canSdkInit">
          <div class="camera-drop-down">
            <label for="camera">{{
              translationsHelper.getTranslationByKey("VS_CAMERA_LABEL")
            }}</label>
            <select
              class="form-select mb-2"
              id="camera"
              aria-label="Cameras"
              (change)="changeCamera($event)"
            >
              <option *ngFor="let camera of cameras; index as i" [value]="i">
                {{ camera.label }}
              </option>
            </select>
          </div>
          <div  class="video-container" style="justify-content: center; align-items: center;">
            <p class="video-notifications" #notificationMessage></p>
            <div class="wrap">
              <img id="mask" src="./../../../assets/mask.svg" alt="" />
              <video
                id="monitor-video"
                muted="true"
                autoplay="true"
                playsinline="isMobileDevice"
                #monitorVideo
              >
                Your browser does not support HTML5 video.
              </video>
            </div>
            <p #sessionTimer class="session-timer">0:00</p>  
          </div>
          
          <p class="text-end" id="scan-action-btns" style="text-align: center !important;">
            <button
              class="btn btn-primary"
              style="margin-bottom: 2px;"
              [style.background-color]="corporationColor"
              [style.border]="corporationColor"
              (click)="startMonitoring()"
              [disabled]="isScanning"
            >
              {{ translationsHelper.getTranslationByKey("VS_START_SCAN") }}
            </button>
            <button
              class="btn btn-secondary ms-2"
              style="margin-bottom: 2px;"
              (click)="stopMonitoring()"
              [disabled]="!isScanning"
            >
              {{ translationsHelper.getTranslationByKey("VS_STOP_SCAN") }}
            </button>
            <!-- <button class="btn btn-danger ms-2" (click)="terminateMonitoring()">
            Finalizar
          </button> -->
          </p>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-6">
    <div class="measurements-container">
      <div class="row">
        <div class="col-sm-6 col-md-6 p-1" [ngStyle]="{'order': vitalsConfig?.heartBeatMeasurement?.order, 'display': vitalsConfig?.heartBeatMeasurement?.visible}">
          <app-facial-analysis-measurement
            #heartBeatMeasurement
            [title]="vitalsConfig?.heartBeatMeasurement?.title"
            [icon]="vitalsConfig?.heartBeatMeasurement?.icon"
            [value]="'---'"
            [color]="corporationColor"
          ></app-facial-analysis-measurement>
        </div>
        <div class="col-sm-6 col-md-6 p-1" [ngStyle]="{'order': vitalsConfig?.oxygenSaturation?.order, 'display': vitalsConfig?.oxygenSaturation?.visible}">
          <app-facial-analysis-measurement
            #oxygenSaturation
            [title]="vitalsConfig?.oxygenSaturation?.title"
            [icon]="vitalsConfig?.oxygenSaturation?.icon"
            [value]="'---'"
            [color]="corporationColor"
          ></app-facial-analysis-measurement>
        </div>
        <div class="col-sm-6 col-md-6 p-1" [ngStyle]="{'order': vitalsConfig?.breathingRateMeasurement?.order, 'display': vitalsConfig?.breathingRateMeasurement?.visible}">
          <app-facial-analysis-measurement
            #breathingRateMeasurement
            [title]="vitalsConfig?.breathingRateMeasurement?.title"
            [icon]="vitalsConfig?.breathingRateMeasurement?.icon"
            [value]="'---'"
            [color]="corporationColor"
          ></app-facial-analysis-measurement>
        </div>
        <div class="col-sm-6 col-md-6 p-1" [ngStyle]="{'order': vitalsConfig?.stressLevelMeasurement?.order, 'display': vitalsConfig?.stressLevelMeasurement?.visible}">
          <app-facial-analysis-measurement
            #stressLevelMeasurement
            [title]="vitalsConfig?.stressLevelMeasurement?.title"
            [icon]="vitalsConfig?.stressLevelMeasurement?.icon"
            [value]="'---'"
            [color]="corporationColor"
          ></app-facial-analysis-measurement>
        </div>
        <div class="col-sm-6 col-md-6 p-1" [ngStyle]="{'order': vitalsConfig?.bloodPressure?.order, 'display': vitalsConfig?.bloodPressure?.visible}">
          <app-facial-analysis-measurement
            #bloodPressure
            [title]="vitalsConfig?.bloodPressure?.title"
            [icon]="vitalsConfig?.bloodPressure?.icon"
            [value]="'---'"
            [color]="corporationColor"
          ></app-facial-analysis-measurement>
        </div>
        <div class="col-sm-6 col-md-6 p-1" [ngStyle]="{'order': vitalsConfig?.wellnessLevel?.order, 'display': vitalsConfig?.wellnessLevel?.visible}">
          <app-facial-analysis-measurement
            #wellnessLevel
            [title]="vitalsConfig?.wellnessLevel?.title"
            [icon]="vitalsConfig?.wellnessLevel?.icon"
            [value]="'---'"
            [color]="corporationColor"
          ></app-facial-analysis-measurement>
        </div>
        <!-- New integrations
            author: victorisimoo
            date: 29/05/2023
          --->
        <div class="col-sm-6 col-md-6 p-1" [ngStyle]="{'order': vitalsConfig?.hemoglobinagglucosylated?.order, 'display': vitalsConfig?.hemoglobinagglucosylated?.visible}">
          <app-facial-analysis-measurement
            #hemoglobinagglucosylated
            [title]="vitalsConfig?.hemoglobinagglucosylated?.title"
            [icon]="vitalsConfig?.hemoglobinagglucosylated?.icon"
            [value]="'---'"
            [color]="corporationColor"
          ></app-facial-analysis-measurement>
        </div>
        <div class="col-sm-6 col-md-6 p-1" [ngStyle]="{'order': vitalsConfig?.hemoglobin?.order, 'display': vitalsConfig?.hemoglobin?.visible}">
          <app-facial-analysis-measurement
            #hemoglobin
            [title]="vitalsConfig?.hemoglobin?.title"
            [icon]="vitalsConfig?.hemoglobin?.icon"
            [value]="'---'"
            [color]="corporationColor"
          ></app-facial-analysis-measurement>
        </div>
      </div>
    </div>
  </div>
</div>
