import { QueryParamsService } from './../../services/query-params.service';
import { ApiService } from './../../services/api.services';

import { FacialAnalysisMeasurementComponent } from './../facial-analysis-measurement/facial-analysis-measurement.component';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MediaDevicesHelper } from 'src/app/helpers/mediaDevices';
import monitor,
{
  AlertData,
  DeviceOrientation,
  HealthMonitorSession,
  SessionState,
  VitalSigns,
  VitalSignsResults,
} from '@binah/web-sdk';
import { NotificationHelper } from 'src/app/helpers/notifications';
import { TranslationsHelper } from 'src/app/helpers/translations';
import { BrowserDetectorHelper } from 'src/app/helpers/browserDetection';

@Component({
  selector: 'app-facial-analysis',
  templateUrl: './facial-analysis.component.html',
  styleUrls: ['./facial-analysis.component.scss']
})

export class FacialAnalysisComponent implements OnInit, OnDestroy {
  @ViewChild('monitorVideo') monitorVideo!: ElementRef;
  @ViewChild('notificationMessage') notificationMessage!: ElementRef;
  @ViewChild('sessionTimer') sessionTimer!: ElementRef;
  @ViewChild('scan-action-btns') scanActionBtns!: ElementRef;
  @ViewChild('heartBeatMeasurement') heartBeatMeasurement!: FacialAnalysisMeasurementComponent;
  @ViewChild('breathingRateMeasurement') breathingRateMeasurement!: FacialAnalysisMeasurementComponent;
  @ViewChild('stressLevelMeasurement') stressLevelMeasurement!: FacialAnalysisMeasurementComponent;
  @ViewChild('bloodPressure') bloodPressure!: FacialAnalysisMeasurementComponent;
  @ViewChild('hemoglobin') hemoglobin!: FacialAnalysisMeasurementComponent;
  @ViewChild('hemoglobinagglucosylated') hemoglobinagglucosylated!: FacialAnalysisMeasurementComponent;
  @ViewChild('wellnessLevel') wellnessLevel!: FacialAnalysisMeasurementComponent;
  @ViewChild('oxygenSaturation') oxygenSaturation!: FacialAnalysisMeasurementComponent;

  medicalAppointmentId: any;
  timerInterval: any;
  healthMonitor: HealthMonitorSession;
  license: any;
  selectedCamera: number;
  cameraDevice: string;
  userProfileId: any;
  sessionDuration: number;

  cameras: any[];

  //Flags
  canSdkInit: boolean;
  isScanning: boolean;
  isMobileDevice: boolean;
  cameraStatus: string;

  corporationColor: string;
  corporationFavicon: string;

  vitalsConfig: any;
  vitalsMetaData: any;

  constructor(private renderer: Renderer2,
    public mediaDevicesHelper: MediaDevicesHelper,
    private apiService: ApiService,
    private queryParamsService: QueryParamsService,
    private notificationHelper: NotificationHelper,
    public translationsHelper: TranslationsHelper,
    public browserDetectorHelper: BrowserDetectorHelper) {

    this.healthMonitor = {} as any;
    this.selectedCamera = 0;
    this.sessionDuration = 60;
    this.userProfileId = "";
    this.canSdkInit = false;
    this.isScanning = false;
    this.corporationColor = "#000000";
    this.corporationFavicon = "";
    this.cameraStatus = "LOADING";
    this.cameras = [];
    this.cameraDevice = "";
    this.isMobileDevice = this.browserDetectorHelper.isMobile();
    this.vitalsConfig = [];
    this.vitalsMetaData = {
      heartRate: {
        icon: 'assets/icons/heart-beat.png',
        tag: 'heartBeatMeasurement'
      },
      oxygen_saturation: {
        icon: 'assets/icons/saturarionvs.png',
        tag: 'oxygenSaturation'
      },
      breathing_rate: {
        icon: 'assets/icons/breathing-rate.png',
        tag: 'breathingRateMeasurement'
      },
      stress_level: {
        icon: 'assets/icons/stress-level.png',
        tag: 'stressLevelMeasurement'
      },
      blood_pressure: {
        icon: 'assets/icons/bloodpresure.png',
        tag: 'bloodPressure'
      },
      wellness_level: {
        icon: 'assets/icons/wellness-level.png',
        tag: 'wellnessLevel'
      },
      hemoglobin_glico: {
        icon: 'assets/icons/hemoglobine-gluco.svg',
        tag: 'hemoglobinagglucosylated'
      },
      homoglobin: {
        icon: 'assets/icons/hemoglobine.png',
        tag: 'hemoglobin'
      }
    };
  }

  async ngOnInit(): Promise<void> {
    const params = this.queryParamsService.getQueryParams();

    // Get license url param
    // Decode uri and decode base64 param
    this.license = decodeURIComponent(atob(params.mz));
    this.medicalAppointmentId = params.apid;
    this.corporationColor = params.pcolor;
    await this.initializeSdk();
  }

  async initializeSdk(): Promise<void> {
    //1. Initialize HealthMonitor
    try {
      await monitor.initialize({ licenseKey: this.license });
      await this.getCameraDevices();
      await this.createMonitoringSession();
      await this.getVitalsConfig();
    } catch (error) {
      console.log("Ha ocurrido un error inicializando", error);
    }
  }

  async getVitalsConfig() {
    try {
      const params = this.queryParamsService.getQueryParams();
      this.vitalsConfig = await this.apiService.getVitalsConfig();
      if (this.vitalsConfig.hasOwnProperty('code')) throw Error(this.vitalsConfig.description);
      this.vitalsConfig = this.vitalsConfig.parameters.map((item: any) => ({
        name: item.name,
        order: item.order,
        title: this.translationsHelper.getTranslationByKey(item.textKey),
        visible: item[params.src] ? 'block' : 'none',
        ...this.vitalsMetaData[item.name]
      })).reduce((acc: any, vital: any) => ({ ...acc, [vital.tag]: vital }), {});
    } catch (error) {
      console.log(error);
    }
  }

  async getCameraDevices(): Promise<void> {
    try {
      await this.mediaDevicesHelper.getMediaDevices();
      if (!this.mediaDevicesHelper.doesUserHaveCamera()) {
        this.cameraStatus = "NO_CAMERAS";
        throw new Error("Parece que no tienes cámaras");
      }

      const hasPermissions = await this.mediaDevicesHelper.requestCameraPermissions()
      if (!hasPermissions) {
        this.cameraStatus = "NO_PERMISSION";
        throw new Error("Parece que la cámara no tiene permisos");

      }

      this.cameraStatus = "";
      await this.mediaDevicesHelper.getMediaDevices();
      this.cameras = this.mediaDevicesHelper.cameras;
      this.canSdkInit = true;
      this.cameraDevice = this.mediaDevicesHelper.cameras[this.selectedCamera].deviceId;
    } catch (error) {
      console.log(error);
    }
  }

  //2. Create HealthMonitorSession
  async createMonitoringSession(): Promise<void> {
    try{
      setTimeout(async () => {
        try {
          const options = {
            input: this.monitorVideo?.nativeElement,
            cameraDeviceId: this.cameraDevice,
            processingTime: this.sessionDuration,
            orientation: DeviceOrientation.PORTRAIT,
            onError: (error: AlertData) => {
              console.log(error);
              if(error.code == 3003){
                this.notificationHelper.showModal('Error', this.translationsHelper.getTranslationByKey('SV_MEASUREMENT_CODE_INVALID_RECENT_DETECTION_RATE_ERROR') + ' ' + this.translationsHelper.getTranslationByKey('VS_NO_FACE') + '.'
                  , 'warning', this.translationsHelper.getTranslationByKey('VS_AGREED'), this.corporationColor);
              }else {
                this.notificationHelper.showModal('Error', this.translationsHelper.getTranslationByKey('VS_AN_ERROR_HAS_OCCURRED'), 'warning', this.translationsHelper.getTranslationByKey('VS_AGREED'), this.corporationColor);
              }
            },

            onVitalSigns: (vitalSigns: VitalSigns) => {
              console.log("Se han recibido los signos vitales", vitalSigns);
            },

            //onFinalResults callback
            onFinalResults: (vitalSignsResults: VitalSignsResults) => {
              this.notificationHelper.showModal(
                  this.translationsHelper.getTranslationByKey('VS_FINAL_RESULTS'),
                  this.translationsHelper.getTranslationByKey('VS_WEB_ANALYSIS_FINISHED_MODAL_DESCRIPTION'),
                  'success',
                  this.translationsHelper.getTranslationByKey('VS_AGREED'),
                  this.corporationColor);
              this.printFinalReport(<VitalSignsResults> vitalSignsResults);
              let newVitalSings = {
                bloodPressure: vitalSignsResults.results.bloodPressure && vitalSignsResults.results.bloodPressure.value !== undefined ? vitalSignsResults.results.bloodPressure.value : {diastolic: 0, systolic: 0},
                heartRate: vitalSignsResults.results.heartRate && vitalSignsResults.results.heartRate.value !== undefined ? vitalSignsResults.results.heartRate.value : 0,
                breathingRate: vitalSignsResults.results.breathingRate && vitalSignsResults.results.breathingRate.value !== undefined ? vitalSignsResults.results.breathingRate.value: 0,
                oxygenSaturation: vitalSignsResults.results.oxygenSaturation && vitalSignsResults.results.oxygenSaturation.value !== undefined ? vitalSignsResults.results.oxygenSaturation.value: 0,
                hemoglobinA1C: vitalSignsResults.results.hemoglobinA1c && vitalSignsResults.results.hemoglobinA1c.value !== undefined ? vitalSignsResults.results.hemoglobinA1c.value : 0,
                hemoglobin: vitalSignsResults.results.hemoglobin && vitalSignsResults.results.hemoglobin.value !== undefined ? vitalSignsResults.results.hemoglobin.value : 0,
                wellnessLevel: vitalSignsResults.results.wellnessLevel && vitalSignsResults.results.wellnessLevel.value !== undefined ? vitalSignsResults.results.wellnessLevel.value : 0,
                wellnessIndex: vitalSignsResults.results.wellnessIndex && vitalSignsResults.results.wellnessIndex.value !== undefined ? vitalSignsResults.results.wellnessIndex.value : 0,
                lfhf: vitalSignsResults.results.lfhf && vitalSignsResults.results.lfhf.value !== undefined ? vitalSignsResults.results.lfhf.value : 0,
                meanRri: vitalSignsResults.results.meanRri && vitalSignsResults.results.meanRri.value !== undefined ? vitalSignsResults.results.meanRri.value : 0,
                pnsIndex: vitalSignsResults.results.pnsIndex && vitalSignsResults.results.pnsIndex.value !== undefined ? vitalSignsResults.results.pnsIndex.value : 0,
                pnsZone: vitalSignsResults.results.pnsZone && vitalSignsResults.results.pnsZone.value !== undefined ? vitalSignsResults.results.pnsZone.value : 0,
                rmssd: vitalSignsResults.results.rmssd && vitalSignsResults.results.rmssd.value !== undefined ? vitalSignsResults.results.rmssd.value : 0,
                sd1: vitalSignsResults.results.sd1 && vitalSignsResults.results.sd1.value !== undefined ? vitalSignsResults.results.sd1.value : 0,
                sd2: vitalSignsResults.results.sd2 && vitalSignsResults.results.sd2.value !== undefined ? vitalSignsResults.results.sd2.value : 0,
                sdnn: vitalSignsResults.results.sdnn && vitalSignsResults.results.sdnn.value !== undefined ? vitalSignsResults.results.sdnn.value : 0,
                snsIndex: vitalSignsResults.results.snsIndex && vitalSignsResults.results.snsIndex.value !== undefined ? vitalSignsResults.results.snsIndex.value : 0,
                snsZone: vitalSignsResults.results.snsZone && vitalSignsResults.results.snsZone.value  !== undefined ? vitalSignsResults.results.snsZone.value : 0,
                stressIndex: vitalSignsResults.results.stressIndex && vitalSignsResults.results.stressIndex.value !== undefined ? vitalSignsResults.results.stressIndex.value : 0,
                stressLevel: vitalSignsResults.results.stressLevel && vitalSignsResults.results.stressLevel.value !== undefined ? vitalSignsResults.results.stressLevel.value : 0,
              }
              this.apiService.sendVitals(this.medicalAppointmentId, <VitalSigns> newVitalSings);
            },

            //onFaceDetected
            //5. Face detection callback onFaceDetected
            onFaceDetected: (detected: Boolean) => {
              if (!detected && this.healthMonitor.getState() < 3) {
                this.setErrorMessage(this.translationsHelper.getTranslationByKey('VS_NO_FACE'));
              } else {
                this.clearErrorMessage();
              }
            },

            /**
             * 
             * @param state 
             * 0: iniciando
             * 1: activo
             * 2: midiendo
             * 3: parando
             * 4: terminado
             */
            onStateChange: (state: SessionState) => {
              if (state === SessionState.MEASURING) {
                this.isScanning = true;
                this.logStateChange(SessionState.MEASURING);
              }

              if (state === SessionState.STOPPING) {
                this.stopMonitoring();
                clearInterval(this.timerInterval);
                this.setMeasurementsLoadingState(false);
                this.logStateChange(SessionState.STOPPING);
                setTimeout(() => {
                  this.logStateChange(SessionState.TERMINATED);
                }, 2000);
              }
            }
          };
          this.healthMonitor = await monitor.createFaceSession(options);
        } catch (error) {
          this.notificationHelper.showModal('Error', this.translationsHelper.getTranslationByKey('VS_AN_ERROR_HAS_OCCURRED'), 'warning', this.translationsHelper.getTranslationByKey('VS_AGREED'));
          console.log(error);
        }
      }, 1000)
    }catch(error_two){
      this.notificationHelper.showModal('Error', this.translationsHelper.getTranslationByKey('VS_AN_ERROR_HAS_OCCURRED'), 'warning', this.translationsHelper.getTranslationByKey('VS_AGREED'));
      console.log(error_two);
    }    
  }

  //4. Start HealthMonitorSession
  startMonitoring() {
    try {
      this.logStateChange(SessionState.ACTIVE);
      this.healthMonitor.start();
      this.setMeasurementsLoadingState(true);
      this.initializeTimer();
    } catch (error) {
      this.notificationHelper.showModal('Error', this.translationsHelper.getTranslationByKey('VS_AN_ERROR_HAS_OCCURRED'), 'warning', this.translationsHelper.getTranslationByKey('VS_AGREED'));
      console.log(error);
    }
  }

  stopMonitoring() {
    this.isScanning = false;
    this.healthMonitor.stop();
  }

  terminateMonitoring() {
    this.healthMonitor.terminate();
  }

  logStateChange(state: SessionState) {
    this.apiService.changeSessionStatus(this.medicalAppointmentId, state.toString());
  }

  initializeTimer() {
    let sessionTime: number = 1;
    this.timerInterval = setInterval(() => {
      if (sessionTime <= this.sessionDuration) {
        const minutes: string = sessionTime / 60 >= 1 ? Math.trunc(sessionTime / 60).toString() : "0";
        let seconds: string = (sessionTime % 60).toString();
        seconds = seconds.toString().length === 1 ? `0${seconds.toString()}` : seconds;
        this.renderer.setProperty(this.sessionTimer.nativeElement, 'textContent', `${minutes}:${seconds}`);
        sessionTime++;
      }
    }, 1000)
  }

  async changeCamera(event: Event) {
    const target = event.target as HTMLInputElement;
    this.selectedCamera = Number(target.value);
    this.cameraDevice = this.mediaDevicesHelper.cameras[this.selectedCamera].deviceId;
    await this.createMonitoringSession();
  }

  setErrorMessage(message: string) {
    this.renderer.setProperty(this.notificationMessage.nativeElement, 'textContent', message)
    this.renderer.addClass(this.notificationMessage.nativeElement, 'notification-error');
  }

  clearErrorMessage() {
    this.renderer.setProperty(this.notificationMessage.nativeElement, 'textContent', "")
    this.renderer.removeClass(this.notificationMessage.nativeElement, 'notification-error');
  }

  /**
   * Sets the value and state of a FacialAnalysisMeasurementComponent
   * @param type Measurement type
   * @param value Measurement value
   */
  setMeasurement(type: number, value: any | number) {
    switch (type) {
      case 1: //Heart beat measurement
        this.heartBeatMeasurement.value = Number(value);
        this.heartBeatMeasurement.isLoading = false;
        break;
      case 3:  // Breathing rate
        this.breathingRateMeasurement.value = Number(value);
        this.breathingRateMeasurement.isLoading = false;
        break;
    }
  }

  /**
   * Prints final Monitor Report
   */
  printFinalReport(report: VitalSignsResults) {
    this.heartBeatMeasurement.value = report.results.heartRate && report.results.heartRate.value ? this.translationsHelper.checkIfNaN(report.results.heartRate.value) : this.translationsHelper.checkIfNaN(NaN);
    this.breathingRateMeasurement.value = report.results.breathingRate && report.results.breathingRate.value ? this.translationsHelper.checkIfNaN(report.results.breathingRate.value) : this.translationsHelper.checkIfNaN(NaN);
    this.stressLevelMeasurement.value = report.results.stressLevel && report.results.stressLevel.value ? this.translationsHelper.getStressLevelTextValue(report.results.stressLevel.value) : this.translationsHelper.checkIfNaN(NaN);
    if(report.results.bloodPressure){
      if(isNaN(report.results.bloodPressure.value.systolic) || isNaN(report.results.bloodPressure.value.diastolic)){
        this.bloodPressure.value = this.translationsHelper.checkIfNaN(report.results.bloodPressure.systolic.value);
      }else {
        this.bloodPressure.value = `${this.translationsHelper.checkIfNaN(report.results.bloodPressure.value.systolic)} / ${this.translationsHelper.checkIfNaN(report.results.bloodPressure.value.diastolic)}`;
      }
    }else{
      this.bloodPressure.value = this.translationsHelper.checkIfNaN(NaN);
    }

    this.wellnessLevel.value = report.results.wellnessLevel && report.results.wellnessLevel.value ? this.translationsHelper.getWellnessLevelTextValue(report.results.wellnessLevel.value) : this.translationsHelper.checkIfNaN(NaN); 
    this.oxygenSaturation.value = this.translationsHelper.checkIfNaN(NaN); // this value is not being returned by the API: 28/05/2023
    this.hemoglobin.value = report.results.hemoglobin && report.results.hemoglobin.value ? this.translationsHelper.checkIfNaN(report.results.hemoglobin.value) : this.translationsHelper.checkIfNaN(NaN);
    this.hemoglobinagglucosylated.value = report.results.hemoglobinA1c && report.results.hemoglobinA1c.value ? this.translationsHelper.checkIfNaN(report.results.hemoglobinA1c.value) : this.translationsHelper.checkIfNaN(NaN);
  }

  /**
   * Sets loading state to all FacialAnalysisMeasurementComponents at once
   * @param state Boolean
   */
  setMeasurementsLoadingState(state: boolean) {
    this.heartBeatMeasurement.isLoading = state;
    this.breathingRateMeasurement.isLoading = state;
    this.stressLevelMeasurement.isLoading = state;
    this.bloodPressure.isLoading = state;
    this.hemoglobin.isLoading = state;
    this.hemoglobinagglucosylated.isLoading = state;
    this.oxygenSaturation.isLoading = state;
    this.wellnessLevel.isLoading = state;
  }

  ngOnDestroy(): void {
    this.terminateMonitoring();
  }
}