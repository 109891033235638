<div class="measure-container card text-center">
  <img class="measure-icon mx-auto my-2" #iconImg [src]="icon" alt="" />
  <p class="measure-title">{{ title }}</p>
  <h5 *ngIf="isLoading">
    <div class="spinner-border spinner-border-sm video-notifications"role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </h5>
  <h5 *ngIf="!isLoading" class="measure-value">{{ value }}</h5>
</div>
